import CoverPhoto from '@/components/CoverPhoto';
import CustomerButtons from '@/components/CustomerButtons';
import Welcome from '@/components/join/Welcome';
import Paper from '@/components/Paper';
import { PrismicRichText, richTextToString } from '@/components/RichText';
import Sidebar from '@/components/Sidebar';

import { Box, Button, Flex } from '@chakra-ui/react';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { LocalBusinessJsonLd, NextSeo } from 'next-seo';
import Link from 'next/link';
import { createClient } from 'prismicio';
import { HomePageDocumentData, TopicDocument } from 'prismicio-types';
import { useState } from 'react';
import GoogleAnalytics from 'shared/utils/GoogleAnalytics';

function Home({ pageData, topics = [] }: InferGetStaticPropsType<typeof getStaticProps>) {
  const [coverImage] = useState(pageData.cover_photo.url);
  const siteUrl = process.env.NEXT_PUBLIC_URL ?? '';

  return (
    <>
      <GoogleAnalytics />
      <NextSeo
        canonical={siteUrl}
        description={richTextToString(pageData.tagline)}
        openGraph={{
          url: siteUrl,
          title: 'Circlework',
          description: richTextToString(pageData.tagline),
          images: [
            {
              url: `${siteUrl}/og-image.jpg`,
              width: 1200,
              height: 630,
              alt: 'Circlework',
              type: 'image/jpeg',
            },
          ],
          site_name: 'Circlework',
        }}
        title='Circlework'
      />
      <LocalBusinessJsonLd
        address={{
          streetAddress: 'Trewin Park',
          addressLocality: 'Morley',
          addressRegion: 'WA',
          postalCode: '6062',
          addressCountry: 'AU',
        }}
        areaServed={[
          {
            geoMidpoint: {
              latitude: '-31.880650',
              longitude: '115.890352',
            },
            geoRadius: '1000',
          },
        ]}
        description={richTextToString(pageData.tagline)}
        geo={{
          latitude: '-31.880650',
          longitude: '115.890352',
        }}
        id={siteUrl}
        images={[`${siteUrl}/og-image.jpg`]}
        name='Circlework'
        type='ExerciseAction'
        url={siteUrl}
      />
      <Flex>
        <Box maxW='container.md'>
          <Flex justifyContent='flex-end'>
            <CustomerButtons />
          </Flex>
          <Paper borderTopLeftRadius={10} flexDir='column'>
            <CoverPhoto url={coverImage ?? ''} />

            <Box p={8} pos='relative'>
              <Box pos='absolute' right={4} top={-5}>
                <Link href='/offer'>
                  <Button colorScheme='teal'>The Training Offer</Button>
                </Link>
              </Box>
              <Welcome
                tagline={richTextToString(pageData.tagline)}
                welcome={richTextToString(pageData.welcome)}
              />
              <PrismicRichText field={pageData.content} />
            </Box>
          </Paper>
        </Box>
        <Sidebar topics={topics} />
      </Flex>
    </>
  );
}

export async function getStaticProps({ previewData }: GetStaticPropsContext) {
  try {
    const client = createClient({ previewData });
    const pageResponse = await client.getSingle('home_page');
    const allTopics = await client.getAllByType('topic');
    if (!pageResponse.data || !allTopics) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        pageData: pageResponse.data as HomePageDocumentData,
        topics: allTopics.map((topic) => topic as TopicDocument),
      },
    };
  } catch (error) {
    return {
      notFound: true,
    };
  }
}

export default Home;
