import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import Link from 'next/link';

interface Props {
  welcome: string;
  tagline: string;
}

function Welcome({ welcome, tagline }: Props) {
  return (
    <>
      <Heading fontWeight='400' pb={4}>
        {welcome}
      </Heading>
      <Text mb={2} pb={2}>
        {tagline}
      </Text>
      <Flex mb={4}>
        <Box bgColor='teal.500' mr={2} w='1' />
        <Text>
          Check the
          <Link href='/events'>
            <Button colorScheme={'teal'} mx={2} size='xs'>
              Events page
            </Button>
          </Link>
          for classes and workshops.
        </Text>
      </Flex>
    </>
  );
}

export default Welcome;
